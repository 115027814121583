<template>
  <RevList :has-external-borders="false">
    <RevAccordionItem>
      <template #prefix>
        <IconCheckInCircle />
      </template>
      <template #title>
        {{ i18n(translations.unlimitedTalkAndTextTitle) }}
      </template>
      <template #body>
        <p class="body-1">
          {{ i18n(translations.unlimitedTalkAndTextDescription) }}
        </p>
      </template>
    </RevAccordionItem>

    <RevAccordionItem>
      <template #prefix>
        <IconCheckInCircle />
      </template>
      <template #title>
        {{ i18n(translations.premiumCoverageTitle) }}
      </template>
      <template #body>
        <div class="space-y-12">
          <p class="body-1">{{ i18n(translations.populationCoverage) }}</p>

          <RevLink
            class="body-1-link block"
            target="_blank"
            :to="i18n(translations.populationCoverageLink)"
          >
            {{ i18n(translations.populationCoverageLinkLabel) }}
          </RevLink>
        </div>
      </template>
    </RevAccordionItem>
  </RevList>
</template>

<script setup lang="ts">
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevAccordionItem } from '@ds/components/AccordionItem'
import { RevLink } from '@ds/components/Link'
import { RevList } from '@ds/components/List'
import { IconCheckInCircle } from '@ds/icons/IconCheckInCircle'

import translations from './VerizonNetworkList.translations'

const i18n = useI18n()
</script>
