export default {
  appleWatchAccordionTitle: {
    id: 'product_page_visible_by_verizon_drawer_apple_watch_accordion_title',
    defaultMessage: 'Add an Apple Watch line free ($10/month value)',
  },
  appleWatchAccordionBody: {
    id: 'product_page_visible_by_verizon_drawer_apple_watch_accordion_body',
    defaultMessage:
      'Pair your Apple Watch at no extra charge, saving you $10/month.',
  },
  internetTitle: {
    id: 'product_page_visible_by_verizon_drawer_internet_accordion_title',
    defaultMessage: 'Save $10/month on Verizon Home Internet',
  },
  internetBody: {
    id: 'product_page_visible_by_verizon_drawer_internet_accordion_body',
    defaultMessage: 'Get Verizon Home Internet for as little as $40/month',
  },
}
