export default {
  unlimitedTalkAndTextTitle: {
    id: 'product_page_visible_by_verizon_drawer_verizon_network_list_unlimited_talk_and_text_title',
    defaultMessage: 'Unlimited talk, text, and data',
  },
  unlimitedTalkAndTextDescription: {
    id: 'product_page_visible_by_verizon_drawer_verizon_network_list_unlimited_talk_and_text_description',
    defaultMessage:
      'Get the unlimited data, messages and minutes you love without hidden fees raising your cost.',
  },
  premiumCoverageTitle: {
    id: 'product_page_visible_by_verizon_drawer_verizon_network_list_premium_coverage_title',
    defaultMessage: 'Premium coverage from Verizon’s network',
  },
  populationCoverage: {
    id: 'product_page_visible_by_verizon_drawer_verizon_network_list_population_coverage',
    defaultMessage:
      'Verizon’s 5G network covers 99% of the US population and is constantly getting better.',
  },
  populationCoverageLink: {
    id: 'product_page_visible_by_verizon_drawer_verizon_network_list_population_coverage_link',
    defaultMessage: 'https://www.visible.com/plans/coverage',
  },
  populationCoverageLinkLabel: {
    id: 'product_page_visible_by_verizon_drawer_verizon_network_list_population_coverage_link_label',
    defaultMessage: 'See coverage details',
  },
}
