export default {
  mexicoAndCanadaAccordionTitle: {
    id: 'product_page_visible_by_verizon_drawer_mexico_and_canada_accordion_title',
    defaultMessage: 'Talk & Text in Mexico & Canada',
  },
  mexicoAndCanadaAccordionBody: {
    id: 'product_page_visible_by_verizon_drawer_mexico_and_canada_accordion_body',
    defaultMessage:
      'Calling from Canada and Mexico is unlimited for domestic calls, or internationally back to the US.',
  },
  globalPassAccordionTitle: {
    id: 'product_page_visible_by_verizon_drawer_global_pass_accordion_title',
    defaultMessage: 'Global Pass',
  },
  globalPassAccordionBody: {
    id: 'product_page_visible_by_verizon_drawer_global_pass_accordion_body',
    defaultMessage: `Travel internationally with ease on our fast and affordable network,  now with 1 included day Global Pass accumulated per month.

      First day is free then it’s: $5/day for Canada/Mx/PR and USVI & $10/day in other ~140`,
  },
  includedCountriesAccordionTitle: {
    id: 'product_page_visible_by_verizon_drawer_included_countries_accordion_title',
    defaultMessage: 'Calling to 30+ countries & unlimited texting to 200+',
  },
  includedCountriesAccordionBody: {
    id: 'product_page_visible_by_verizon_drawer_included_countries_accordion_body',
    defaultMessage: `International calling included from the US to Calling to {callingLink, html}. Call up to 500 minutes per month at no additional cost.

    Unlimited texting included from the US to & unlimited texting to {textingLink, html} worldwide. Spam Protection screens incoming calls and filters spam. Blocks high-risk spam and robocalls at our network before they even get to you.`,
  },
  includedCountriesCallingLabel: {
    id: 'product_page_visible_by_verizon_drawer_included_countries_calling_label',
    defaultMessage: '30+ countries',
  },
  includedCountriesPlansLink: {
    id: 'product_page_visible_by_verizon_drawer_included_countries_plans_link',
    defaultMessage: 'https://www.visible.com/plans',
  },
  includedCountriesTextingLabel: {
    id: 'product_page_visible_by_verizon_drawer_included_countries_texting_label',
    defaultMessage: '200+ countries & territories',
  },
}
