<template>
  <div class="pt-24">
    <div class="space-y-24 px-24 pb-48">
      <div class="flex justify-center">
        <RevIllustration
          :alt="i18n(translations.drawerTitle)"
          :height="190"
          src="/img/visible-by-verizon/visible-by-verizon-header.svg"
          :width="342"
        />
      </div>

      <div class="space-y-8">
        <h3 class="heading-1">{{ i18n(translations.title) }}</h3>

        <div class="space-y-16">
          <p>
            {{ i18n(translations.intro) }}
          </p>

          <RevInfoBlock
            :icon="IconLightbulb"
            :title="i18n(translations.info)"
            variant="info"
          />

          <p>
            {{ i18n(translations.howItWorks) }}
          </p>

          <RevTextList class="[counter-set:list-item]" variant="ordered">
            <RevTextListItem>
              {{ i18n(translations.firstListItem) }}
            </RevTextListItem>
            <RevTextListItem>
              {{ i18n(translations.secondListItem) }}
            </RevTextListItem>
            <RevTextListItem>
              {{ i18n(translations.thirdListItem) }}
            </RevTextListItem>
            <RevTextListItem>
              {{ i18n(translations.fourthListItem) }}
            </RevTextListItem>
          </RevTextList>

          <p>
            {{ i18n(translations.howItWorksEligibility) }}
          </p>
        </div>
      </div>

      <h4 class="heading-2">{{ i18n(translations.includedTitle) }}</h4>

      <ul class="space-y-24">
        <li>
          <RevLink class="body-2-link" @click="scrollTo(verizonNetwork)">
            {{ i18n(translations.verizonNetworkLink) }}
          </RevLink>
        </li>
        <li>
          <RevLink class="body-2-link" @click="scrollTo(hotspot)">
            {{ i18n(translations.hotspotLink) }}
          </RevLink>
        </li>
        <li>
          <RevLink class="body-2-link" @click="scrollTo(internationalPerks)">
            {{ i18n(translations.internationalPerksLink) }}
          </RevLink>
        </li>
        <li>
          <RevLink class="body-2-link" @click="scrollTo(appleWatch)">
            {{ i18n(translations.appleWatchLink) }}
          </RevLink>
        </li>
      </ul>

      <div ref="verizonNetwork" class="space-y-8">
        <h5 class="heading-3">
          {{ i18n(translations.verizonNetworkTitle) }}
        </h5>

        <VerizonNetworkList />
      </div>

      <div ref="hotspot" class="space-y-8">
        <h5 class="heading-3">
          {{ i18n(translations.unlimitedHotspotHeading) }}
        </h5>

        <HotspotList />
      </div>

      <div ref="internationalPerks" class="space-y-8">
        <h5 class="heading-3">
          {{ i18n(translations.internationalPerksHeading) }}
        </h5>

        <InternationalPerksList />
      </div>

      <div ref="appleWatch" class="space-y-8">
        <h5 class="heading-3">
          {{ i18n(translations.appleWatchHeading) }}
        </h5>

        <InternetAndAppleWatchList />
      </div>
    </div>

    <div class="bg-surface-default-low space-y-8 px-24 py-32">
      <h4 class="heading-2">{{ i18n(translations.FAQTitle) }}</h4>

      <FAQList />
    </div>
    <div class="bg-surface-default-low h-[12.5rem]" />
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevIllustration } from '@ds/components/Illustration'
import { RevInfoBlock } from '@ds/components/InfoBlock'
import { RevLink } from '@ds/components/Link'
import { RevTextList } from '@ds/components/TextList'
import { RevTextListItem } from '@ds/components/TextListItem'
import { IconLightbulb } from '@ds/icons/IconLightbulb'

import FAQList from '../FAQList/FAQList.vue'
import HotspotList from '../HotspotList/HotspotList.vue'
import InternationalPerksList from '../InternationalPerksList/InternationalPerksList.vue'
import InternetAndAppleWatchList from '../InternetAndAppleWatchList/InternetAndAppleWatchList.vue'
import VerizonNetworkList from '../VerizonNetworkList/VerizonNetworkList.vue'

import translations from './VisibleByVerizonDrawerBody.translations'

const i18n = useI18n()

const verizonNetwork = ref<Element | undefined>()
const hotspot = ref<Element | undefined>()
const internationalPerks = ref<Element | undefined>()
const appleWatch = ref<Element | undefined>()

function scrollTo(element?: Element) {
  if (!element) return

  element.scrollIntoView({ behavior: 'smooth' })
}
</script>
