<template>
  <RevEditorialCard
    :key="id"
    :alt="imageAlt"
    :content="text"
    :cta="i18n(translations.readMore)"
    data-selector="buying-guide-card"
    data-test="article-card"
    :image="imageSrc"
    :image-props="imageProps"
    :rel="link?.rel"
    :target="link?.target"
    :title="title"
    :title-kicker="category"
    title-tag="h3"
    :to="link?.href"
    @click="sendTrackingData"
  />
</template>

<script lang="ts" setup>
import { useRoute } from '#imports'

import type { ContentLink } from '@backmarket/http-api/src/api-specs-content/models/content-link'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { RevEditorialCard } from '@ds/components/EditorialCard'

import translations from './ArticleCard.translations'

export type ArticleCard = {
  title: string
  category: string
  text: string
  id: string
  imageSrc: string
  imageAlt: string
  link: ContentLink
  imageProps?: {
    width: number
    height: number
  }
}

const props = defineProps<ArticleCard>()

const i18n = useI18n()
const route = useRoute()

const { trackClick } = useTracking()

function sendTrackingData() {
  const trackingData = {
    name: props.title,
    zone: route.name?.toString() || '',
    value: {
      articleCategory: props.category,
    },
  }

  trackClick(trackingData)
}
</script>
