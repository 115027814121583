<template>
  <RevList :has-external-borders="false">
    <RevAccordionItem>
      <template #prefix>
        <IconCheckInCircle />
      </template>
      <template #title>
        {{ i18n(translations.mexicoAndCanadaAccordionTitle) }}
      </template>
      <template #body>
        <p class="body-1 whitespace-pre-line">
          {{ i18n(translations.mexicoAndCanadaAccordionBody) }}
        </p>
      </template>
    </RevAccordionItem>

    <RevAccordionItem>
      <template #prefix>
        <IconCheckInCircle />
      </template>
      <template #title>
        {{ i18n(translations.includedCountriesAccordionTitle) }}
      </template>
      <template #body>
        <p class="body-1 whitespace-pre-line">
          <FormattedMessage
            :definition="translations.includedCountriesAccordionBody"
          >
            <template #callingLink>
              <RevLink
                target="_blank"
                :to="i18n(translations.includedCountriesPlansLink)"
              >
                {{ i18n(translations.includedCountriesCallingLabel) }}
              </RevLink>
            </template>

            <template #textingLink>
              <RevLink
                target="_blank"
                :to="i18n(translations.includedCountriesPlansLink)"
              >
                {{ i18n(translations.includedCountriesTextingLabel) }}
              </RevLink>
            </template>
          </FormattedMessage>
        </p>
      </template>
    </RevAccordionItem>

    <RevAccordionItem>
      <template #prefix>
        <IconCheckInCircle />
      </template>
      <template #title>
        {{ i18n(translations.globalPassAccordionTitle) }}
      </template>
      <template #body>
        <p class="body-1 whitespace-pre-line">
          {{ i18n(translations.globalPassAccordionBody) }}
        </p>
      </template>
    </RevAccordionItem>
  </RevList>
</template>

<script setup lang="ts">
import FormattedMessage from '@backmarket/nuxt-module-i18n/FormattedMessage.vue'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevAccordionItem } from '@ds/components/AccordionItem'
import { RevLink } from '@ds/components/Link'
import { RevList } from '@ds/components/List'
import { IconCheckInCircle } from '@ds/icons/IconCheckInCircle'

import translations from './InternationalPerksList.translations'

const i18n = useI18n()
</script>
