<template>
  <RevCardCarousel
    v-if="hasArticles"
    :next-alternative-text="i18n(translations.nextSlideText)"
    :prev-alternative-text="i18n(translations.prevSlideText)"
  >
    <ArticleCard
      v-for="article in articles"
      :key="article.id"
      class="mb-16"
      data-test="article-card"
      v-bind="article"
    />
  </RevCardCarousel>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevCardCarousel } from '@ds/components/CardCarousel'

import ArticleCard, {
  type ArticleCard as ArticleCardType,
} from '~/scopes/article/components/ArticleCard/ArticleCard.vue'

import translations from './ArticleCardsCarousel.translations'

const props = defineProps<{
  articles: ArticleCardType[]
}>()

const i18n = useI18n()

const hasArticles = computed(() => props.articles.length > 0)
</script>
